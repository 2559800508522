<template>
  <div v-if="!!yearlyRosetteInfo.length" class="history-container">
    <VueText class="history-title">Geçmiş Aylar Özetim</VueText>
    <div class="history-rosettes">
      <div v-for="(item, index) in yearlyRosetteInfo" :key="index" class="history-rosette-item">
        <div class="rosette-wrapper">
          <img class="image" :src="getBadgeImage(item.status)" alt="rosette" />
          <VueText :style="monthTextStyle(item)" class="month">
            {{ item.month.toUpperCase() }}
          </VueText>
          <VueText class="point" v-if="!!item.point">
            {{ `${item.point}&nbsp;Puan` }}
          </VueText>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import { Gamification } from '@/services/Api/index';

export default {
  components: {
    VueText,
  },
  data() {
    return {
      yearlyRosetteInfo: [],
    };
  },
  props: {
    triggerRefresh: {
      type: Boolean,
    },
  },
  watch: {
    triggerRefresh: {
      handler() {
        this.getRosetteHistory();
      },
    },
  },
  computed: {
    monthTextStyle() {
      return item => {
        return {
          'text-shadow': item.status !== 4 ? this.getMonthNameStyle(item.status) : 'none',
          color: item.status !== 4 ? 'white' : '#CDCFCF',
        };
      };
    },
  },
  mounted() {
    this.getRosetteHistory();
  },
  methods: {
    getRosetteHistory() {
      Gamification.getYearlyRosetteInfo().then(res => {
        this.yearlyRosetteInfo = res.data.Data.rosetteSuccesInfo;
      });
    },
    getMonthNameStyle(status) {
      const styles = {
        1: '1px 1px #EAC013',
        2: '1px 1px #EAC013',
        3: '1px 1px #FD9292',
        default: '1px 1px #CDCFCF',
      };
      return styles[status] || styles.default;
    },
    getBadgeImage(status) {
      const badgeImages = {
        1: require('../../../../assets/rosettes/success-rosettes.svg'),
        2: require('../../../../assets/rosettes/success-rosettes.svg'),
        3: require('../../../../assets/rosettes/fail-rosette.svg'),
        default: require('../../../../assets/rosettes/passive-rosette.svg'),
      };
      return badgeImages[status] || badgeImages.default;
    },
  },
};
</script>

<style scoped lang="scss">
.history-container {
  margin: 20px 25px;
}
.history-title {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 0 10px;
}

.history-rosettes {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  background-color: #f9fdff;
  border: 1px solid #eef4f6;
  border-radius: 15px;
  margin: 20px 0;
}

.history-rosette-item {
  text-align: center;
  margin: 15px 10px;
}

.rosette-content {
  text-align: center;
}

image {
  width: 105px;
  height: 124px;
}

.rosette-wrapper {
  position: relative;
  display: inline-block;
}

.month {
  top: 25%;
  left: 50%;
  padding: 5px;
  color: #ffffffc7;
  font-size: 12px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.point {
  left: 20%;
  font-size: 16px;
  top: 35%;
  position: absolute;
  font-weight: 700;
  color: #b77c0a;
  text-align: center;
  font-family: Play;
  text-shadow: 1px 1px #c8a83575;
}
</style>
