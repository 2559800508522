<template>
  <div v-if="getRosetteVisibility">
    <div v-if="isVisibleSurpriseAwardBox" class="surprise-box" @click="openSupriseModal">
      <div class="title">Süpriz Ödülleri Kaçırma</div>
      <div class="content">
        {{ supriseBoxContent }}
      </div>
      <img
        src="~@/assets/rosettes/rosette-surprise-key-button.svg"
        alt="rosette key"
        class="box-key"
      />
    </div>
    <BrandSurveyPopup
      style="align-items: center !important;"
      :show.sync="showSupriseModal"
      @update:show="closeSupriseModal"
    >
      <RosetteRewardPopupContent />
    </BrandSurveyPopup>
    <div>
      <VueText class="rosettes-title">Aktif+ Görevlerim</VueText>
      <div class="rosette-container">
        <div v-for="(rosette, index) in getRosettesInfo" :key="index">
          <div class="rosette-box" @click="selectCurrentRosette(rosette)">
            <VueText
              sizeLevel="16"
              style="font-weight: 500; color: #23303D; margin-top:15px;text-align: center;"
              >{{ rosette.rosetteName }}</VueText
            >
            <div class="circular-progress">
              <div class="progress-bar" :id="`progress-bar-${index}`">
                <img
                  :src="`${rosette.rosetteImageUrl}`"
                  style="position: absolute; height: 35px; width: 35px;"
                />
              </div>
            </div>
            <VueText style="color: #23303D; font-weight: 300; margin:2% 0;"
              >{{ rosette.rosetteCount }} / {{ rosette.rosetteReferenceCount }}</VueText
            >
          </div>
          <DynamicRosetteButton
            :rosette="rosette"
            :hasNpsSurvey="hasNpsSurvey"
            :hasActiveMarathons="hasActiveMarathons"
            @openNpsSurveyPopup="openNpsSurveyPopup"
          />
        </div>
      </div>
      <BrandSurveyPopup style="align-items: center !important; " :show.sync="showRosetteModal">
        <div style="width: 1000px; padding: 20px">
          <RosettePopup :selectedRosette="selectedRosette" />
          <DynamicRosetteButton
            :rosette="selectedRosette"
            :hasNpsSurvey="hasNpsSurvey"
            :hasActiveMarathons="hasActiveMarathons"
            @openNpsSurveyPopup="openNpsSurveyPopup"
          />
        </div>
      </BrandSurveyPopup>
    </div>
    <NpsSurvey
      v-if="isNpsRosette"
      :npsSurveyId="npsSurveyId"
      :isRosetteNps="true"
      @closeModal="closeRosetteModal"
    />
    <RosettesHistory :triggerRefresh="triggerRefresh" />
  </div>
</template>

<script>
import VueText from '@/components/shared/VueText/VueText.vue';
import BrandSurveyPopup from '@/components/brand/Modals/BrandModal/BrandSurveyPopup.vue';
import RosettePopup from './RosettePopup.vue';
import RosetteInfo from '@/mixins/rosetteInfo.js';
import NpsSurvey from '@/views/pages/secure/NpsSurvey.vue';
import DynamicRosetteButton from '@/modules/rosettes/components/DynamicRosetteButton.vue';
import GameApi from '@/services/Api/gamification';
import StorageHelper from '@/utils/storageHelper';
import RosettesHistory from './RosettesHistory.vue';
import { Common } from '@/services/Api/index';
import gtmUtils from '@/mixins/gtmUtils.js';
import StaticContent from '@/services/Api/staticContents';
import RosetteRewardPopupContent from '../RosetteRewardPopupContent.vue';

export default {
  name: 'RosettesDetail',
  components: {
    VueText,
    RosettePopup,
    BrandSurveyPopup,
    DynamicRosetteButton,
    NpsSurvey,
    RosettesHistory,
    RosetteRewardPopupContent,
  },
  mixins: [RosetteInfo, gtmUtils],
  data() {
    return {
      selectedRosette: {},
      showRosetteModal: false,
      isNpsRosette: false,
      hasActiveMarathons: false,
      hasActiveMarathonsTriggered: false,
      hasNpsSurvey: false,
      npsSurveyId: 0,
      showScore: false,
      supriseBoxContent: '',
      showSupriseModal: false,
      surpriseAwardPopupContent: '',
      triggerRefresh: false,
      isVisibleSurpriseCrate: false,
    };
  },
  methods: {
    openSupriseModal() {
      this.showSupriseModal = !this.showSupriseModal;
    },
    closeSupriseModal() {
      this.getRosetteRewardStatu();
      this.triggerRefresh = !this.triggerRefresh;
    },
    getRosetteSurpriseAwardBox() {
      StaticContent.getStaticContent('RosetteSurpriseAwardBox').then(res => {
        const { content } = res.data.Data;
        this.supriseBoxContent = content;
      });
    },
    selectCurrentRosette(rosette) {
      this.pushDataLayerEvent('ecommerce');
      this.pushDataLayerEvent('rozet', {
        type: rosette.rosetteName,
      });
      this.selectedRosette = rosette;
      this.showRosetteModal = true;
      this.isNpsRosette = false;
    },
    calculateProgressValue(f) {
      return (100 / f.rosetteReferenceCount) * f.rosetteCount;
    },
    openNpsSurveyPopup() {
      this.isNpsRosette = !this.isNpsRosette;
      this.showRosetteModal = false;
    },
    async closeRosetteModal() {
      this.isNpsRosette = false;
      await this.getNpsRosetteDetail();
      await this.getRosettesList();
      await this.setProgressValue();
    },
    async activeMarathons() {
      if (!this.hasActiveMarathonsTriggered) {
        const result = await GameApi.checkActiveMarathons();
        result?.data?.Data?.marathon?.forEach(f => {
          if (f.marathonName == 'Haftanın Bulmacası') {
            new StorageHelper({ id: 'PlayableInfo' }).set(f);
            this.hasActiveMarathons = true;
          }
        });
        this.hasActiveMarathonsTriggered = true;
      }
    },
    getNpsRosetteDetail() {
      Common.getNpsRosetteDetail().then(res => {
        if (res?.data?.Data) {
          this.npsSurveyId = res?.data?.Data.surveyId;
          this.hasNpsSurvey = !!res.data.Data.surveyId && res.data.Data.surveyId != 0;
        }
      });
    },
  },
  async updated() {
    await this.setProgressValue();
  },
  async mounted() {
    this.getRosetteSurpriseAwardBox();
    this.getRosetteRewardStatu();
    await this.setProgressValue();
    await this.getRosettesList();
    await this.activeMarathons();
  },
  watch: {
    getRosettesInfo: {
      handler() {
        this.checkNpsRosette(this.getNpsRosetteDetail);
      },
    },
  },
};
</script>

<style scoped lang="scss">
.rosettes-title {
  font-size: 20px;
  font-weight: 600;
  margin: 20px 0 0 10px;
}
.rosette-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  text-align: -webkit-center;
  margin-bottom: 20%;
}
.rosette-box {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  display: flex;
  width: 155px;
  height: 170px;
  cursor: pointer;
  margin: auto;
  margin-top: 20px;
}
.rosette-box:hover {
  border: 1px solid rgba(0, 0, 0, 0.4);
}
.circular-progress {
  display: flex;
  border-radius: 8px;
  background: #fff;
  row-gap: 30px;
  flex-direction: column;
  align-items: center;
}
.progress-bar {
  position: relative;
  height: 70px;
  width: 70px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}
.progress-bar::before {
  content: '';
  position: absolute;
  height: 60px;
  width: 60px;
  border-radius: 50%;
  background-color: #fff;
}
.rosette-modal-content {
  margin: 30px;
}

.meter {
  box-sizing: content-box;
  height: 6px;
  position: relative;
  margin: 10px 0 10px 0;
  background: #d9d9d9;
  border-radius: 30px;
  box-shadow: inset 0 -1px 1px #d9d9d9;
}
.meter > span {
  display: block;
  height: 100%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background: linear-gradient(315deg, #d20051 0%, #ffa300 100%);
  position: relative;
  overflow: hidden;
}
.progress-bar-modal {
  position: relative;
  height: 100px;
  width: 100px;
  border-radius: 50%;
  display: flex;
  background-color: #ededed;
  align-items: center;
  justify-content: center;
}
.progress-bar-modal::before {
  content: '';
  position: absolute;
  height: 82px;
  width: 82px;
  border-radius: 50%;
  background-color: #fff;
}
.surprise-box {
  background-image: url('~@/assets/rosettes/rosette-surprise-box.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  flex-direction: column;
  cursor: pointer;
  display: flex;
  width: 100%;
  max-width: 100%;
  height: 185px;
  .title {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding: 20px 0 0 20px;
  }
  .content {
    color: rgba(255, 255, 255, 0.8);
    font-size: 15px;
    font-weight: 500;
    padding: 12px 0 0 20px;
    line-height: 21px; /* 150% */
    text-align: start;
  }
  .box-key {
    width: 167px;
    height: 44px;
    display: flex;
    align-self: center;
    margin-top: 20px;
  }
}
</style>
